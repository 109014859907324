/*
Custom File Input Style
Based on: https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
*/

.cc-input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    +label {
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        margin: 0;

        .feather {
            width: 0.8em;
            height: 0.8em;
            margin-right: 0.25em;
            position: relative;
            top: -4px;
        }
    }

    &:focus,
    &.has-focus {
        +label {
        }
    }
}